<template>
  <div class="talk-home" v-wechat-title="titleMeta">
    <div class="header">
      <div class="header-placeholder">
        <div class="header-inner">
          <img class="logo" :src="logo" alt="logo" />
          <a
            class="tabnav topic"
            :class="{ current: currnet == 'topic' }"
            @click="clickHeaderNav('topic')"
            >今日话题</a
          >
          <a
            class="tabnav follow"
            @click="clickHeaderNav('follow')"
            v-if="loginStatus"
            >关注动态</a
          >
        </div>

        <UserBar></UserBar>
      </div>
    </div>
    <div class="talk-list">
      <div class="left-type">
        <ol class="type-list" ref="type-list">
          <li
            class="type-item icon-tujian"
            :class="{ cur: currentType == item.type }"
            v-for="(item, index) in dataTypes"
            :key="index"
            @click="initFlowData(item)"
          >
            <i :class="item.icon"></i>{{ item.name }}
          </li>
        </ol>
      </div>
      <div class="middle-list" ref="middle-list">
        <!-- 轮播 -->
        <div
          class="module-carousel"
          :class="{ 'v-loading': carouselData.length == 0 }"
        >
          <transition-group tag="ul" class="carousel-list" name="list">
            <li
              class="carousel-item"
              v-for="(item, i) in carouselData"
              :key="item.id"
              v-show="i === currentIndex"
              :pdata="item.pdata"
              @mouseenter="stop"
              @mouseleave="go"
            >
              <a :href="item.url" @click.prevent="gotopicpage(item)">
                <div class="carousel-img-box img-warp">
                  <img :src="item.img169" alt="" />
                </div>
                <div class="carousel-info-box">
                  <h3>#{{ item.zt_name }}#</h3>
                  <p>
                    {{ item.zt_desc }}
                  </p>
                  <div class="comcount">
                    <span class="seecount">
                      <i class="see-icon"></i>{{ item.view_totals || 0 }}
                    </span>
                    <span class="dsccount">
                      <i class="dsc-icon"></i>{{ item.comment_totals || 0 }}
                    </span>
                  </div>
                </div>
              </a>
            </li>
          </transition-group>
          <ol class="carousel-idot">
            <li
              :class="{ active: currentIndex == idx }"
              v-for="(em, idx) in carouselData"
              :key="idx"
              @mouseover="change(idx)"
            ></li>
          </ol>
        </div>
        <!-- 信息流 -->
        <div
          class="module-list"
          :class="{ 'ml-loading': listData.length == 0 }"
        >
          <ul class="list" v-if="listData.length">
            <li
              class="list-item"
              v-for="(item, index) in listData"
              :key="index"
              :pdata="item.pdata"
              @click="gotopicpage(item)"
            >
              <!-- 有图有评论 -->
              <template v-if="item.img169 && item.comment_totals">
                <div class="list-info-box">
                  <div class="title">
                    <a>#{{ item.zt_name }}#</a>
                  </div>
                  <div class="content">
                    <a>
                      {{ item.zt_desc }}
                    </a>
                  </div>
                  <div class="comcount">
                    <span class="seecount">
                      <i class="see-icon"></i>{{ item.view_totals || 0 }}
                    </span>
                    <span class="dsccount">
                      <i class="dsc-icon"></i>{{ item.comment_totals || 0 }}
                    </span>
                  </div>
                </div>
                <div class="list-img-box">
                  <!-- :href="item.url" -->
                  <a class="img-warp">
                    <img :src="item.img169" alt="" />
                  </a>
                </div>
                <div class="buzz" v-if="item.hot_comment">
                  <i class="com-icon"></i>
                  <img class="avator" :src="item.hot_comment.avatar" />
                  <span class="username">{{
                    `${item.hot_comment.nickname}：`
                  }}</span>
                  <span
                    class="usercomment"
                    v-html="formatEmojiBr(item.hot_comment.content)"
                  ></span>
                </div>
              </template>
              <!-- 有图无评论 -->
              <template v-else-if="item.img169">
                <div class="list-info-box">
                  <div class="title">
                    <a>#{{ item.zt_name }}#</a>
                  </div>
                  <div class="content">
                    <a>
                      {{ item.zt_desc }}
                    </a>
                  </div>
                  <div class="comcount">
                    <span class="seecount">
                      <i class="see-icon"></i>{{ item.view_totals || 0 }}
                    </span>
                    <span class="dsccount">
                      <i class="dsc-icon"></i>{{ item.comment_totals || 0 }}
                    </span>
                  </div>
                </div>
                <div class="list-img-box">
                  <a class="img-warp">
                    <!-- :href="item.url" -->
                    <img :src="item.img169" alt="" />
                  </a>
                </div>
              </template>
              <!-- 无图无评论 -->
              <template v-else>
                <div class="list-nopic-box">
                  <div class="title">
                    <a>#{{ item.zt_name }}#</a>
                  </div>
                  <div class="content">
                    <a>
                      {{ item.zt_desc }}
                    </a>
                  </div>
                  <div class="comcount">
                    <span class="seecount">
                      <i class="see-icon"></i>{{ item.view_totals || 0 }}
                    </span>
                    <span class="dsccount">
                      <i class="dsc-icon"></i>{{ item.comment_totals || 0 }}
                    </span>
                  </div>
                  <div class="buzz" v-if="item.hot_comment">
                    <i class="com-icon"></i>
                    <img class="avator" :src="item.hot_comment.avatar" />
                    <span class="username">{{
                      `${item.hot_comment.nickname}：`
                    }}</span>
                    <span
                      class="usercomment"
                      v-html="formatEmojiBr(item.hot_comment.content)"
                    ></span>
                  </div>
                </div>
              </template>
            </li>
          </ul>

          <loadIcon v-if="loading" />
          <div v-if="noMoreData" class="isnodata">亲，已经到底了</div>
        </div>
      </div>
      <div class="right-module">
        <!-- 热门投票 -->
        <div class="module-vote common-module">
          <p class="module-title vote-icon">热门投票</p>
          <ol class="vote-list">
            <li
              class="vote-item"
              v-for="(item, index) in voteData"
              :key="index"
              :pdata="item.pdata"
              @click="goHuaTi(item)"
            >
              <p>
                <span class="text">{{ item.section_name }}</span>
                <span class="num">{{ item.vote_totals }}人参与</span>
              </p>
              <span class="vote-btn">投票 ></span>
            </li>
          </ol>
        </div>
        <!-- 热门话题 -->
        <div class="module-topic common-module" ref="module-topic">
          <div id="hot-flow-module">
            <p class="module-title hot-icon">热门话题</p>
            <ol class="hot-topic-list">
              <li
                class="hot-item"
                v-for="(m, n) in talkData"
                :key="n"
                :pdata="m.pdata"
                @click="goHuaTi(m)"
              >
                <a>
                  <i class="serial-num" :class="`no${n + 1}`">{{ n + 1 }}</i>
                  #{{ m.zt_name }}#
                </a>
                <span class="icon hot" v-if="n <= 2"></span>
              </li>
              <!-- <li class="hot-item">
                <a> <i class="serial-num no2">2</i>8年持续奋斗 1亿人脱贫</a>
                <span class="icon new"></span>
              </li>-->
            </ol>
          </div>
        </div>
        <!-- 关于我们 -->
        <div class="module-about common-module">
          <div class="instructions">
            <p>
              <span class="mr15"
                ><a href="/talk/about?type=us" target="_blank"
                  >联系我们</a
                ></span
              >
              <!-- <a href="/talk/about?type=us" target="_blank">关于我们</a> -->
            </p>
            <p>互联网新网信息服务许可证：31120170005</p>
            <p>24小时违法和不良信息举报电话：021-60897139</p>
            <p>网上有害信息举报专区</p>
            <p>tantao.com All Rright Reserve 版权所有</p>
            <p><a style="color:#999;" href="https://beian.miit.gov.cn" target="_blank">备案号：沪ICP备14038113号-30</a></p>
          </div>
        </div>
      </div>
      <div class="tool-bar" @click="backScrollTop"></div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {
  getTopicTypes,
  getTopicList,
  getHotData,
  getLunboData,
} from '@/api/home.js';
// import { getTopicReadNum } from '@/api/axios.request';
import {
  getOffset,
  getScrollPosition,
  addClass,
  removeClass,
  scrollTo,
  // isHresolution,
} from '@/util/index.js';
import { formatEmojiBr } from '@/util/methods.js';
import loadIcon from '@/components/loadIcon/index.vue';
import UserBar from '@/components/userBar/userBar.vue';
import VueCookies from 'vue-cookies';
import { activeLog, baiduLog } from '@/util/log.js';
import logoPath from '../../assets/image/logo-2.png';
// import logoSmPath from '../../assets/image/logo-s.png';
let FormlY = '';
export default {
  name: 'Home',
  components: {
    loadIcon,
    UserBar,
  },
  data() {
    return {
      titleMeta: '【探讨】精准清爽看新闻，评论投票有态度！',
      pagetype: 'index',
      newstype: 'tantao',
      timer: '',
      loadlock: false, // 加载信息数据的lock
      loading: true, // 加载
      currnet: 'topic',
      dataTypes: [],
      noMoreData: false,
      currentType: 'tuijian',
      currentPage: 1,
      currentIndex: 0,
      carouselData: [],
      listData: [],
      talkData: [], // 热门话题
      voteData: [], // 热门投票
      logo: logoPath,
    };
  },
  created() {
    this.initCateData();
    this.$nextTick(() => {
      clearInterval(this.timer);
      this.timer = setInterval(() => {
        this.autoPlay();
      }, 5000);
    });
    // const isH = isHresolution();
    // if (!isH) this.logo = logoSmPath;
  },
  beforeRouteEnter(to, from, next) {
    FormlY = from.fullPath;
    next();
  },
  mounted() {
    this.scrollLoadData();
    this.reportLog();
  },
  computed: {
    ...mapState(['token', 'userInfo', 'hasUseRefer', 'userInfo', 'loginStatus']),
  },
  watch: {},
  methods: {
    formatEmojiBr,
    // 日志上报
    reportLog() {
      baiduLog();
      let pdata = VueCookies.get('TJ_DATA');
      VueCookies.remove('TJ_DATA');
      if (pdata) {
        pdata = pdata.split('|');
      } else {
        pdata = [];
      }

      let vfrom = '';
      if (!this.hasUseRefer && document.referrer) {
        vfrom = document.referrer;
        this.$store.commit('SET_HASUSEERFER', true);
      } else {
        if (!FormlY || FormlY === '/') vfrom = 'null';
        else vfrom = `${location.protocol}//${location.host}${FormlY}`;
      }

      activeLog({
        accid: this.userInfo ? this.userInfo.id : -1,
        btype: pdata[0] || 'null',
        subtype: pdata[1] || 'null',
        pageType: this.pagetype,
        idx: pdata[2] || 'null',
        newstype: this.newstype,
        pgnum: pdata[4] || 'null',
        from: vfrom,
        to: window.location.href,
        fr_url: vfrom,
      });
    },
    // 重置
    reset() {
      this.loadlock = false;
      this.currentPage = 1;
      this.currentIndex = 0;
      this.carouselData = [];
      this.listData = [];
      this.currentType = 'tuijian';
    },
    // 导航跳转
    clickHeaderNav(name) {
      // if (name == 'topic') this.$router.push({ path: '/talk/12345678' });
      // else if (name == 'buzz') this.$router.push({ path: '/talk/buzz' });
      // this.currnet = name;
      if (name == 'follow') this.$router.push({path: '/talk/concern'})
    },
    // 初始化话题类表类型
    initCateData() {
      getTopicTypes().then((response) => {
        response.data.forEach((item) => {
          item.icon = `icon-${item.type}`;
        });
        this.dataTypes = response.data;
        //其他栏目暂时下线,只保留推荐
        this.dataTypes = [
          { icon: 'icon-tuijian', id: '001', name: '推荐', type: 'tuijian' },
        ];
        this.initFlowData();
      });
      getHotData().then((response) => {
        if (!response) return;
        const { hot_talk, hot_vote } = response;
        hot_talk.forEach((item, idx) => {
          item.pdata = `index|hot_talk|${idx}|0`;
        });
        hot_vote.forEach((item, idx) => {
          item.pdata = `index|hot_vote|${idx}|0`;
        });
        this.talkData = hot_talk;
        this.voteData = hot_vote;
        this.moduleScrollFixed();
      });
    },
    // 初始化信息流数据
    initFlowData(em) {
      scrollTo(0, 400, () => {});
      this.reset();
      if (em) this.currentType = em.type;
      this.loading = true;
      const page = this.currentPage;
      this.loadlock = true;
      this.loadLunboData(page, () => {
        getTopicList(this.currentType, page)
          .then((response) => {
            if (!response) {
              this.loading = false;
              this.noMoreData = true;
              return;
            }
            // 列表数据
            const lbIds = this.carouselData.map((m) => m.id); // 轮播数据
            const res = response.data.slice(0);
            const newres = [];
            let index = 0;
            res.forEach((item) => {
              if (lbIds.length && lbIds.indexOf(item.id) > -1) return;
              item.pdata = `index|${this.currentType}|${index}|0|${page}`;
              newres.push(item);
              ++index;
            });
            // 加载数据
            this.listData = newres;
            this.loading = false;
            this.loadlock = false;
          })
          .catch(() => {
            this.loading = false;
            this.noMoreData = true;
          });
      });
    },
    // 加载轮播数据
    loadLunboData(page, cb) {
      if (this.currentType !== 'tuijian') {
        cb && cb();
        return;
      }
      getLunboData()
        .then((response) => {
          if (!response || !response.data) {
            cb && cb();
            return;
          }
          const lb = response.data;
          lb.forEach((item, idx) => {
            item.pdata = `index|lunbo|${idx}|0|${page}`;
          });
          this.carouselData = lb;
          cb && cb();
        })
        .catch(() => {
          cb && cb();
        });
    },
    // 加载信息流数据
    loadFlowData() {
      this.currentPage += 1;
      this.loading = true;
      const page = this.currentPage;
      getTopicList(this.currentType, page)
        .then((response) => {
          if (!response) {
            this.loading = false;
            this.noMoreData = true;
            return;
          }
          const data = response.data.slice(0);
          // this.getreadnum(data, (data) => {
          //   data.forEach((item, idx) => {
          //     item.pdata = `index|${this.currentType}|${idx}|0|${page}`;
          //     this.listData.push(item);
          //   });
          // });
          data.forEach((item, idx) => {
            item.pdata = `index|${this.currentType}|${idx}|0|${page}`;
            this.listData.push(item);
          });
          this.loadlock = false;
          this.loading = false;
          this.noMoreData = false;
        })
        .catch(() => {
          this.loading = false;
          this.noMoreData = true;
        });
    },
    // 动态获取阅读数
    // getreadnum(data, cb) {
    //   cb && cb(data);
    //   // // 获取阅读数
    //   let arrTopicId = [];
    //   data.forEach((item) => {
    //     arrTopicId.push(item.id);
    //   });
    //   getTopicReadNum({
    //     talk_id_list: arrTopicId,
    //   })
    //     .then((r) => {
    //       if (r.data && r.data.result) {
    //         const curread = r.data.result;
    //         for (var k in curread) {
    //           curread[k] = {
    //             view: parseInt(Math.random() * 100),
    //             comment: parseInt(Math.random() * 100),
    //           };
    //         }
    //         console.log(curread, '************************************');
    //         data.forEach((m) => {
    //           if (typeof curread[m.id] !== 'undefined') {
    //             m.readnum = curread[m.id].view;
    //             m.commentnum = curread[m.id].comment;
    //           }
    //         });
    //       }
    //       cb && cb(data);
    //     })
    //     .catch(() => {
    //       cb && cb(data);
    //     });
    // },
    // 滚动监听加载数据
    scrollLoadData() {
      window.addEventListener('scroll', this.handleFun);
    },
    // 滚动监听的回调函数
    handleFun() {
      const scope = this;
      let $middleList = this.$refs['middle-list'];
      if (!$middleList) return;
      let h = $middleList.clientHeight;
      let offsetTop = getOffset($middleList).top;
      let scrollTop = getScrollPosition().y;
      let screenHeight = document.documentElement.clientHeight;

      if (scope.loadlock) return;
      if (!$middleList) $middleList = this.$refs['middle-list'];
      if (!$middleList) return;
      h = $middleList.clientHeight;
      offsetTop = getOffset($middleList).top;
      screenHeight = document.documentElement.clientHeight;
      scrollTop = getScrollPosition().y;
      if (offsetTop + h <= screenHeight + scrollTop + 300) {
        scope.loadlock = true;
        scope.loadFlowData();
      }
    },
    // 左、右侧滚动悬浮
    moduleScrollFixed() {
      this.$nextTick(() => {
        let $moduleTopic = this.$refs['module-topic'];
        let $typeList = this.$refs['type-list'];
        const hotFlowModule = document.getElementById('hot-flow-module');
        let scrollTop = getScrollPosition().y;
        let offsetTop = getOffset($moduleTopic).top;
        setTimeout(() => {
          const rh = $moduleTopic.clientHeight;
          window.addEventListener('scroll', function(e) {
            e.preventDefault();
            scrollTop = getScrollPosition().y;
            if (scrollTop >= rh + offsetTop) {
              addClass(hotFlowModule, 'hot-flow-fixed');
            } else {
              removeClass(hotFlowModule, 'hot-flow-fixed');
            }

            if (scrollTop >= 20) {
              addClass($typeList, 'nav-flow-fixed');
            } else {
              removeClass($typeList, 'nav-flow-fixed');
            }
          });
        }, 1000);
      });
    },
    // 返回顶部
    backScrollTop() {
      scrollTo(0, 400);
    },
    // 跳转话题页面
    gotopicpage(item) {
      if (item.pdata) VueCookies.set('TJ_DATA', item.pdata);
      // 新页面打开
      let routeUrl = this.$router.resolve({
        path: `/talk/${item.id}/`,
      });
      window.open(routeUrl.href, '_blank');
      // this.$router.push({ path: `/talk/${item.id}` });
    },
    // 下面是轮播的方法
    go() {
      clearInterval(this.timer);
      this.timer = setInterval(() => {
        this.autoPlay();
      }, 4000);
    },
    // 停止伦比
    stop() {
      clearInterval(this.timer);
      this.timer = null;
    },
    // 改变下标
    change(index) {
      this.currentIndex = index;
    },
    // 开始轮播
    autoPlay() {
      this.currentIndex++;
      if (this.currentIndex > this.carouselData.length - 1) {
        this.currentIndex = 0;
      }
    },
    // 跳转话题页
    goHuaTi(item) {
      if (!item.url) return;
      if (item.pdata) VueCookies.set('TJ_DATA', item.pdata);
      // 新页面打开
      let routeUrl = this.$router.resolve({
        path: `${item.url}`,
      });
      window.open(routeUrl.href, '_blank');
      // this.$router.push({ path: item.url });
    },
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleFun);
  },
};
</script>

<style lang="scss" scoped>
@import './home.scss';
</style>
<style lang="scss">
.talk-home {
  .talk-list {
    .middle-list {
      .module-list {
        .list-item {
          .usercomment {
            img {
              width: 16px;
              height: 16px;
              margin-right: 2px;
            }
          }
        }
      }
    }
  }
}
</style>
